import React from 'react'
import 'swiper/css'
import AOS from "aos";
import CountUp from 'react-countup';
AOS.init({
    duration: 1200
});

const Stats = () => {
    return (
      <>
        <div className="section bg-secondary text-light py-3 py-lg-5 px-2 px-lg-4" id="stats">
            <div className="container-narrow">
                <div className="row px-4">
                    <div className="col-md-3 col-sm-6">
                        <div className="d-flex py-2"><i className="mr-3 fas fa-clipboard-check fa-4x fa-fw" />
                            <div className="ms-1">
                                <CountUp className='h2 mb-1' end={40} delay={0} enableScrollSpy />
                                <div className="h6">Projects Done</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <div className="d-flex py-2"><i className="mr-3 fas fa-smile fa-4x fa-fw" />
                            <div className="ms-1">
                                <CountUp className='h2 mb-1' end={28} delay={0} enableScrollSpy />
                                <div className="h6">Happy Clients</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <div className="d-flex py-2"><i className="mr-3 fas fa-file-alt fa-4x fa-fw" />
                            <div className="ms-1">
                              <CountUp className='h2 mb-1' end={19} delay={0} enableScrollSpy />
                              <div className="h6">Appreciations</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <div className="d-flex py-2"><i className="mr-3 fas fa-clock fa-4x fa-fw" />
                            <div className="ms-1">
                              <CountUp className='h2 mb-1' end={8665} delay={0} enableScrollSpy />
                                <div className="h6">Support Hours</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </>
    )
}

export default Stats