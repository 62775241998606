import React from 'react'
import './PopupComponents.scss'

const PopupComponents = (props) => {
    return props.trigger ? (
        <div className='popupcomponents'>
            <div className='popupcomponents_main'>
                <span className='close_btn'
                    onClick={() => props.setTrigger(!props.trigger)}> &#x2716;</span>
                {/* <span className='close_btn'
                    onClick={() => props.setTrigger(!props.trigger)}> &#x2716;</span>
                <div className='main_layout'>
                    <div className='head'>
                        <span>XXX</span>
                    </div>
                    <h1 className='hiring_text'>We <span>are</span> hiring.</h1>
                    <span className='join_text'>Join our amazing team</span>
                    <span className='join_text1'></span>
                    <div className='d-flex w-100  align-items-center justify-content-between'>
                        <div className='w-50 d-flex flex-column align-items-center justify-content-center'>
                            <span className='developer_text'>ReactJs Developer</span>
                            <span className='developer_text1'>experience : 1-3 Years</span>
                        </div>
                        <div className='w-50 d-flex flex-column align-items-center justify-content-center'>
                            <span className='developer_text'>ReactJs Developer</span>
                            <span className='developer_text1'>experience : 1-3 Years</span>
                        </div>



                    </div>
                </div> */}
                <img className="w-100 h-100" src='assets/images/weAre.png' />
            </div>

        </div>
    ) : (
        ""
    );
}
export default PopupComponents