import React from 'react'
import 'swiper/css'
import AOS from "aos";
AOS.init({
    duration: 1200
});

const About = () => {
    return (
      <>
      <div className="section pt-3 pt-lg-5 px-2 px-lg-4" id="about">
          <div className="container-narrow">
              <div className="text-center mb-4" data-aos="fade-up">
                  <h2 className="marker marker-center">Why Key Concepts</h2>
              </div>
              <div className="text-center" data-aos="fade-up">
                  <p className="mx-auto mb-3" style={{ maxWidth: '800px' }}>We are software development company and complete IT solution provider that focuses on providing highly qualitative, timely delivered and cost-effective client solutions. Our Key clients are loyal to us because we have volume up their business and has also spread it to world globally.</p>
              </div>
          </div>
          <div className="services">
              <img className="services_shape" src="assets/images/illustrations/bg shape.svg" alt="Our Features and Services" />
              <div className="container d-flex flex-column flex-xl-row">
                  <div className="col-lg-6 col-md-5 px-3" data-aos="fade-right" data-aos-delay={50} data-aos-easing="ease-in-back"><img className="my-3 img-fluid" src="assets/images/illustrations/creative-team.svg" alt="creative-team" /></div>
                  <ul className="services_list">
                      <li className="wrapper d-block" data-aos="fade-left"><div className="border rounded p-3 bg-light mb-3">
                          <div className="text-center text-secondary concepts_img display-3">
                            <img src="assets/images/illustrations/group.svg"  alt='about-1'/></div>
                          <h3 className="text-center h4 mt-2">Expert Tean</h3>
                          <p className="text-center text-small">Highly Experienced and creative team in the combination of technologies, makes our all delivery possible with the best stability
                          </p>
                      </div></li>
                      <li className="d-block wrapper" data-aos="fade-left" data-aos-delay={50}> <div className="border rounded p-3 bg-light mb-3">
                          <div className="text-center text-secondary concepts_img display-3"><img  alt='about-1' src="assets/images/illustrations/customer.svg" /></div>
                          <h3 className="text-center h4 mt-2">Full Support</h3>
                          <p className="text-center text-small">Within or after the development and delivery, Complete support is our guarantee. All clients are given equal importance and full follow up.
                          </p>
                      </div></li>
                      <li className="wrapper d-block" data-aos="fade-left" data-aos-delay={100}> <div className="border rounded p-3 bg-light mb-3">
                          <div className="text-center text-secondary concepts_img display-3"><img  alt='about-1' src="assets/images/illustrations/database.svg" /></div>
                          <h3 className="text-center h4 mt-2">Latest Technology</h3>
                          <p className="text-center text-small">Keeping our selves and also our customer updated is our daily process. Working with all latest technologies and tools results in fast business growth.
                          </p>
                      </div></li>
                      <li className="wrapper d-block" data-aos="fade-left" data-aos-delay={150}><div className="border rounded p-3 bg-light mb-3">
                          <div className="text-center text-secondary concepts_img display-3"><img  alt='about-1' src="assets/images/illustrations/shield.svg" /></div>
                          <h3 className="h4 mt-2 text-center">Data Security</h3>
                          <p className="text-small text-center">We never compromise with safety and security. We are taking care of all your valuable and important data with privacy
                          </p>
                      </div></li>
                  </ul>
              </div>
          </div>
          {/* <div className="container position-r">
              <img className="about_shape" src="assets/images/illustrations/bg 2.svg" alt="The Right Digital Tools for Business" />
              <div className="row py-3 py-lg-5">
                  <div className="col-lg-6 col-md-5 px-3 order-md-last" data-aos="fade-left" data-aos-delay={50} data-aos-easing="ease-in-back"><img className="my-3 img-fluid" src="assets/images/illustrations/development.svg" alt="development" /></div>
                  <div className="col-lg-6 col-md-7 px-2" data-aos="fade-right" data-aos-delay={100}>
                      <div className="h3 mb-2">Let our <strong>Agile Team</strong> understand and serve your needs.</div>
                      <p className="text-secondary mb-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur vehicula
                          auctor ornare. Nulla non ullamcorper tellus. Vestibulum pulvinar eros nec tortor maximus, iaculis rutrum
                          nibh mollis.</p>
                      <div className="row">
                          <div className="col-lg-6 mb-2">
                              <div className="border border-3 rounded px-2 py-2 d-flex align-items-center">
                                  <div className="badge bg-success me-2"><i className="fas fa-check" /></div>
                                  <div className="text-secondary fw-bold">Active Colloboration</div>
                              </div>
                          </div>
                          <div className="col-lg-6 mb-2">
                              <div className="border border-3 rounded px-2 py-2 d-flex align-items-center">
                                  <div className="badge bg-success me-2"><i className="fas fa-check" /></div>
                                  <div className="text-secondary fw-bold">On-Schedule Delivery</div>
                              </div>
                          </div>
                          <div className="col-lg-6 mb-2">
                              <div className="border border-3 rounded px-2 py-2 d-flex align-items-center">
                                  <div className="badge bg-success me-2"><i className="fas fa-check" /></div>
                                  <div className="text-secondary fw-bold">After Sales Services</div>
                              </div>
                          </div>
                          <div className="col-lg-6 mb-2">
                              <div className="border border-3 rounded px-2 py-2 d-flex align-items-center">
                                  <div className="badge bg-success me-2"><i className="fas fa-check" /></div>
                                  <div className="text-secondary fw-bold">24x7 On-Support</div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="row py-3 py-lg-5 position-r">

                  <img className="speaker lazy" src="assets/images/illustrations/speaker.svg" alt="Join Us" />
                  <div className="col-lg-6 col-md-5 px-3" data-aos="fade-right" data-aos-delay={50} data-aos-easing="ease-in-back"><img className="my-3 img-fluid" src="assets/images/illustrations/work-chat.svg" alt="work-chat" /></div>
                  <div className="col-lg-6 col-md-7 px-2" data-aos="fade-left" data-aos-delay={100}>
                      <div className="h3 mb-2">We believe in <strong>Long Term Partnership</strong>.</div>
                      <p className="text-secondary mb-3">Lorem ipsum dolor sit amet consectetur adipiscing elit primis rutrum,
                          nullam tempor malesuada laoreet tempus blandit pretium etc.</p>
                      <ul className="nav nav-tabs nav-fill" role="tablist">
                          <li className="nav-item" role="presentation"><a className="nav-link p-1 active" aria-current="page" href="#experience" data-bs-toggle="tab"><span className="badge bg-success me-1">1</span>
                              Experience</a></li>
                          <li className="nav-item" role="presentation"><a className="nav-link p-1" href="#flexibility" data-bs-toggle="tab"><span className="badge bg-success me-1">2</span> Flexibility</a></li>
                          <li className="nav-item" role="presentation"><a className="nav-link p-1" href="#results" data-bs-toggle="tab"><span className="badge bg-success me-1">3</span> Results</a></li>
                      </ul>
                      <div className="tab-content border border-top-0 text-secondary p-3" id="myTabContent">
                          <div className="tab-pane fade show active" id="experience" role="tabpanel" aria-labelledby="home-tab">
                              <p>Lorem ipsum dolor sit amet consectetur adipiscing elit primis rutrum, nullam tempor malesuada
                                  laoreet tempus blandit pretium etc.</p>
                              <p>Curabitur vehicula auctor ornare. Nulla non ullamcorper tellus. Vestibulum pulvinar eros nec tortor
                                  maximus, iaculis rutrum nibh mollis.</p>
                          </div>
                          <div className="tab-pane fade" id="flexibility" role="tabpanel" aria-labelledby="profile-tab">
                              <p>Leverage agile frameworks to provide a robust synopsis for high level overviews.</p>
                              <p>Organically grow the holistic world view of disruptive innovation via workplace diversity and
                                  empowerment.</p>
                          </div>
                          <div className="tab-pane fade" id="results" role="tabpanel" aria-labelledby="contact-tab">
                              <p>Capitalize on low hanging fruit to identify a ballpark value added activity to beta test.</p>
                              <p>Nanotechnology immersion along the information highway will close the loop on focusing solely on
                                  the bottom line.</p>
                          </div>
                      </div>
                  </div>
              </div>
          </div> */}
      </div>
      </>
    )
}

export default About