import React from 'react'
import 'swiper/css'
import AOS from "aos";
AOS.init({
    duration: 1200
});

const Services = () => {
    return (
      <>
      <section className="section bg-light py-3 py-lg-5 px-2 px-lg-4" id="services">
        <div className="container-narrow">
            <div className="text-center mb-5" data-aos="fade-up">
                <h2 className="marker marker-center">Our Services</h2>
            </div>
            <div className="text-center" data-aos="fade-up">
                <p className="mx-auto mb-3" style={{ maxWidth: '800px' }}>Explore Our Innovative IT Solutions</p>
            </div>
            <div className="row py-3">
                <div className="mobile_view">
                    <div className="our_services_card  col-md-4 text-center mb-3" data-aos="flip-down" data-aos-delay={100}>

                        <div className="services_card_inner bg-white border rounded p-2">
                            <div className="bg-white rounded-circle d-inline-block p-2"><img className="services_img p-2" src="assets/images/services/service-1.png" width={96} height={96} alt="web design" /></div>
                            <div className="h5 ">Web Development</div>
                            {/* <p className="text-small">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua.</p> */}
                        </div>
                    </div>
                    <div className="our_services_card  col-md-4 text-center mb-3" data-aos="flip-down" data-aos-delay={200}>

                        <div className="services_card_inner bg-white border rounded p-2">
                            <div className="bg-white rounded-circle d-inline-block p-2"><img className="services_img p-2" src="assets/images/services/service-6.png" width={96} height={96} alt="graphic design" /></div>
                            <div className="h5 ">Graphic Design</div>
                            {/* <p className="text-small">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua.</p> */}
                        </div>
                    </div>
                    <div className="our_services_card  col-md-4 text-center mb-3" data-aos="flip-down" data-aos-delay={300}>

                        <div className="services_card_inner bg-white border rounded p-2">
                            <div className="bg-white rounded-circle d-inline-block p-2"><img className="services_img p-2" src="assets/images/services/service-4.png" width={96} height={96} alt="ui-ux" /></div>
                            <div className="h5 ">UI/UX</div>
                            {/* <p className="text-small">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua.</p> */}
                        </div>
                    </div>
                    <div className="our_services_card  col-md-4 text-center mb-3" data-aos="flip-down" data-aos-delay={300}>

                        <div className="services_card_inner bg-white border rounded p-2">
                            <div className="bg-white rounded-circle d-inline-block p-2"><img className="services_img p-2" src="assets/images/services/service-5.png" width={96} height={96} alt="ui-ux" /></div>
                            <div className="h5 ">Mobile App Development</div>
                            {/* <p className="text-small">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua.</p> */}
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>
      </>
    )
}

export default Services