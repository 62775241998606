import React from 'react'
import 'swiper/css'
import AOS from "aos";
AOS.init({
    duration: 1200
});

const HeroSlider = () => {
    return (
      <>
      <header className="section_one" id="home">
          <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
              <div className="carousel-inner">
                  <div className="carousel-item active">
                      <div className="cover">
                          <div className="container px-2">
                              <div className="row">
                                  <div className="col-lg-6">
                                      <div className="mt-5 pt-lg-5">
                                          <h1 className="intro-title marker" data-aos="fade-right" data-aos-delay={50}>
                                            Your Success is Our Mission.
                                          </h1>
                                          <p className="lead fw-normal mt-4" data-aos="fade-right" data-aos-delay={100}>Leverage agile frameworks to
                                              provide a robust synopsis for high level overviews. Iterative approaches to corporate strategy
                                              foster collaborative thinking.</p>
                                      </div>
                                  </div>
                                  <div className="col-lg-6 p-3 pe-lg-0" data-aos="fade-left" data-aos-delay={100}>
                                      <img className="pt-2 img-fluid" src="assets/images/illustrations/building_websites.svg" alt="hello" />
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Next</span>
              </button>
          </div>
          <div className="wave-bg">
              <img src="assets/images/Hero Section_2.svg" alt='hero' />
          </div>
      </header>
      </>
    )
}

export default HeroSlider