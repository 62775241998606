import React from 'react'
import 'swiper/css'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from "swiper";
import AOS from "aos";
AOS.init({
    duration: 1200
});

const Technology = () => {
    return (
      <>
      <section className="section bg-dark-light py-3 py-lg-5 px-2 px-lg-4" id="technology">
          <div className="container-narrow">
              <div className="text-center mb-5" data-aos="fade-up">
                  <h2 className="marker marker-center">Our Technology</h2>
              </div>

              <div className="row">

                  <Swiper
                  data-aos="zoom-in"
                      className=""
                      spaceBetween={20}
                      slidesPerView={6}
                      breakpoints={{
                        320: {
                          slidesPerView: 1,
                        },
                        600: {
                          slidesPerView: 2,
                        },
                        700: {
                            slidesPerView: 3,
                          },
                          800: {
                            slidesPerView: 4,
                          },
                          900: {
                            slidesPerView: 5,
                          },
                          1000: {
                            slidesPerView: 6,
                          },
                      }}
                      autoplay={{
                          delay: 2500,
                          disableOnInteraction: false
                      }}
                      modules={[Autoplay, Pagination, Navigation]}
                      onSlideChange={() => console.log('slide change')}
                      onSwiper={(swiper) => console.log(swiper)}>
                      <SwiperSlide>
                          <div className="d-inline-block p-2"><img className="p-2" src="assets/images/team/react-js-2.png" alt="web design" /></div>
                      </SwiperSlide>
                      <SwiperSlide>
                          <div className="d-inline-block p-2"><img className="p-2" src="assets/images/team/react-native-2.png" alt="web design" /></div>
                      </SwiperSlide>
                      <SwiperSlide>
                          <div className="d-inline-block p-2"><img className="p-2" src="assets/images/team/node-js-2.png" alt="web design" /></div>
                      </SwiperSlide>
                      <SwiperSlide>
                          <div className="d-inline-block p-2"><img className="p-2" src="assets/images/team/Android-Studio-2.png" alt="web design" /></div>
                      </SwiperSlide>
                      <SwiperSlide>
                          <div className="d-inline-block p-2"><img className="p-2" src="assets/images/team/angular-js-2.png" alt="web design" /></div>
                      </SwiperSlide>
                      <SwiperSlide>
                          <div className="d-inline-block p-2"><img className="p-2" src="assets/images/team/Bootstrap-2.png" alt="web design" /></div>
                      </SwiperSlide>
                      <SwiperSlide>
                          <div className="d-inline-block p-2"><img className="p-2" src="assets/images/team/html5-2.png" alt="web design" /></div>
                      </SwiperSlide>
                      <SwiperSlide>
                          <div className="d-inline-block p-2"><img className="p-2" src="assets/images/team/CSS3-2.png" alt="web design" /></div>
                      </SwiperSlide>
                      <SwiperSlide>
                          <div className="d-inline-block p-2"><img className="p-2" src="assets/images/team/Python-2.png" alt="web design" /></div>
                      </SwiperSlide>
                      <SwiperSlide>
                          <div className="d-inline-block p-2"><img className="p-2" src="assets/images/team/Firebase-2.png" alt="web design" /></div>
                      </SwiperSlide>

                  </Swiper>
              </div>
          </div>
      </section>
      </>
    )
}

export default Technology