import './App.css';
import Home from './pages/home';
// import "../node_modules/bootstrap/dist/css/bootstrap.css";
function App() {
  return (
    <div className="App">
    <Home/>
    </div>
  );
}
export default App;
