import React from 'react'
import 'swiper/css'
import { Link } from "react-scroll";
import AOS from "aos";
AOS.init({
    duration: 1200
});

const Header = () => {
    return (
        <>
            <header className="bg-light" style={{ height: '110px' }} >
                <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top shadow-sm flex-column p-0" id="header-nav" role="navigation">
                    <marquee behavior="scroll" direction="left">
                        <img className='header_image1' src='assets//images/illustrations/speaker.svg' />
                        We are looking for someone who can work with ReactJs and NodeJs, and who has experience using these technologies.
                        <img className='header_image' src='assets//images/illustrations/speaker.svg' />
                    </marquee>
                    <div className="container"><a className="link-dark navbar-brand site-title mb-0" href="/">
                        {/* Super Agency */}
                        <img className="logo" alt='logo' src="assets/images/illustrations/NewProject.png" />
                    </a>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon" /></button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav ms-auto me-2">
                                <li className="nav-item"><Link className="nav-link" activeClass="active_menu" smooth spy to="about">About</Link></li>
                                <li className="nav-item"><Link className="nav-link" activeClass="active_menu" smooth spy to="services">Services</Link></li>
                                <li className="nav-item"><Link className="nav-link" activeClass="active_menu" smooth spy to="technology">Technology</Link></li>
                                {/* <li className="nav-item"><Link className="nav-link" activeClass="active_menu" smooth spy to="portfolio">Portfolio</Link></li> */}
                                {/* <li className="nav-item"><Link className="nav-link" activeClass="active_menu" smooth spy to="contact">Contact</Link></li> */}
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
        </>
    )
}

export default Header