import React, { useState } from "react";
import "swiper/css";
import AOS from "aos";

/*
 Custom Components
*/
import Header from "../Components/Header";
import HeroSlider from "../Components/HeroSlider";
import About from "../Components/About";
import Services from "../Components/Services";
import Technology from "../Components/Technology";
import Stats from "../Components/Stats";
import PopupComponents from "../Components/PopupComponents";
// import Portfolio from '../Components/Portfolio';
// import Contact from '../Components/Contact';

AOS.init({
  duration: 1200,
});

function Home() {
  const [trigger, setTrigger] = useState(true);
  return (
    <div>
      {/* nav_bar */}
      <Header />
      <div className="page-content">
        <div id="content">
          <HeroSlider />
          <About />
          <Services />
          <Technology />
          <Stats />

          {/* <Portfolio/> */}

          {/* <Contact/> */}
        </div>
      </div>

      <footer className="py-5 px-2 bg-dark-light" id="footer">
        <div className="container">
          <div className="row" style={{ justifyContent: "space-between" }}>
            <div className="col-lg-2"></div>
            <div className="col-lg-4 col-md-12 mb-3">
              <div className="mb-3">
                <div className="h4">
                  {/* Super Agency */}
                  <img
                    className="logo"
                    alt="footer"
                    src="assets/images/illustrations/NewProject.png"
                  />
                </div>
                <div className="social-nav">
                  <nav role="navigation">
                    <ul className="nav text-light">
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="https://www.instagram.com/codenvision"
                          title="Instagram"
                        >
                          <i className="fab fa-instagram" />
                          <span className="menu-title sr-only">Instagram</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="https://www.linkedin.com/company/codenvision/"
                          title="LinkedIn"
                        >
                          <i className="fab fa-linkedin" />
                          <span className="menu-title sr-only">LinkedIn</span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 mb-3">
              <div className="text-uppercase mb-2">Contact Us</div>
              <div className="text-small">
                <address>
                  <a
                    href="https://goo.gl/maps/sdvimLDAACMtLHdW7"
                    target="_blank"
                    rel="noreferrer"
                  >
                    233, 2nd-floor, Bellissimo Hub, Gaurav Path Road,
                    <br />
                    Pal Gam, Surat, Gujarat 395009
                  </a>
                </address>
                <div className="mb-1">
                  <strong>Email:</strong> hr@codenvision.com
                </div>
              </div>
            </div>
            <div className="col-lg-2"></div>
          </div>
        </div>
        <div className="text-small text-muted  copyright_text">
          <div className="mb-1">
            © Copyright <strong>CodEnvision</strong>. All rights reserved.
          </div>
        </div>
      </footer>
      <div id="scrolltop">
        <a className="btn btn-secondary" href="#top">
          <span className="icon">
            <i className="fas fa-angle-up fa-x" />
          </span>
        </a>
      </div>
      <PopupComponents trigger={trigger} setTrigger={setTrigger} />
    </div>
  );
}
export default Home;
